<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="应急预案" name="first">
            <ayl-table-with-query-object
              :table="emergencyResponseTable"
              @selection-change="selectionTable"
            >
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd('应急预案')">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onDelete"
                >删除</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onSubmitTo"
                >提交</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="应急物资管理" name="second">
            <ayl-table-with-query-object
              :table="emergencySuppliesTable"
              @selection-change="selectionTable"
            >
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd('应急物资管理')">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onDelete"
                >删除</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onSubmitTo"
                >提交</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="应急演练" name="three">
            <ayl-table-with-query-object
              :table="emergencyDrillTable"
              @selection-change="selectionTable"
            >
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd('应急演练')">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onDelete"
                >删除</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onSubmitTo"
                >提交</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="应急处置记录" name="four">
            <ayl-table-with-query-object :table="table" @selection-change="selectionTable">
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd('应急处置记录')">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onDelete"
                >删除</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  plain
                  @click="onSubmitTo"
                >提交</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
import Delete from "@/mixins/delete";
import SubmitTo from "@/mixins/submit-to";
export default {
  mixins: [Delete, SubmitTo],
  data() {
    const vm = this;
    return {
      nav: [{ name: "应急管理" }],

      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "50%",
      tabsName: "应急预案",

      // 删除
      deleteAPI_Id: [
        this.$api_hw.operationSafetyManagement_emergencyPlandDelete,
        "idList",
        "planId",
        "emergencyResponseTable"
      ],

      // 提交
      submitToData: [
        this.$api_hw.operationSafetyManagement_emergencyPlanCommit,
        "idList",
        "planId",
        "emergencyResponseTable"
      ],

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      districtInfo_getDistinctCareUnit: [true, 1, null], // 养护企业信息下拉
      common_getDistinctManageUnit: [true, 2, null], //获取主管单位下拉
      activeName: "first",
      //应急预案
      emergencyResponseTable: {
        api:
          vm.$api_hw.operationSafetyManagement_emergencyPlanListCompanyObject,
        query: {
          queryCondition: {
            bidId: null, //标段名称
            careUnitName: null, //养护企业
            bidManageName: null //主管单位
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "备注",
            width: "100px",
            key: "remark"
          },
          {
            title: "状态",
            width: "100px",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      },

      //应急物质管理
      emergencySuppliesTable: {
        api:
          vm.$api_hw.operationSafetyManagement_emergencySupplyListCompanyObject,
        query: {
          queryCondition: {
            bidId: null,
            careUnitName: null,
            bidManageName: null,
            startTime: null,
            endTime: null
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "配置日期",
            isOptions: true,
            model: [],
            modelName: ["queryCondition.startTime", "queryCondition.endTime"]
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName",
            width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "物资类型",
            // width: "140px",
            key: "supplyType"
          },
          {
            title: "数量",
            // width: "100px",
            key: "num"
          },
          {
            title: "配置时间",
            key: "configureTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "用途",
            // width: "140px",
            key: "usage"
          },
          {
            title: "存放地点",
            // width: "100px",
            key: "storageLocation"
          },
          {
            title: "状态",
            // width: "100px",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            // width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      },

      //应急演练
      emergencyDrillTable: {
        api:
          vm.$api_hw.operationSafetyManagement_emergencyDrillListCompanyObject,
        query: {
          queryCondition: {
            bidId: null,
            careUnitName: null,
            bidManageName: null,
            startTime: null,
            endTime: null
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "演练日期",
            isOptions: true,
            model: [],
            modelName: ["queryCondition.startTime", "queryCondition.endTime"]
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "演练时间",
            key: "drillTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "参与人数",
            // width: "140px",
            key: "participantNum"
          },
          {
            title: "演练内容",
            // width: "100px",
            key: "drillContent"
          },
          {
            title: "状态",
            width: "100px",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      },

      //应急处置记录
      table: {
        api:
          vm.$api_hw
            .operationSafetyManagement_emergencyResponseRecordCompanyObject,
        query: {
          queryCondition: {
            bidId: null,
            careUnitName: null,
            bidManageName: null,
            startTime: null,
            endTime: null
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "处置日期",
            isOptions: true,
            model: [],
            modelName: ["queryCondition.startTime", "queryCondition.endTime"]
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "事件时间",
            key: "eventTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "事件描述",
            // width: "140px",
            key: "eventDesc"
          },
          {
            title: "处置结果",
            width: "100px",
            key: "handleResult"
          },
          {
            title: "状态",
            width: "100px",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  methods: {
    async handleClick() {
      // 每次切换清空选中的数据
      this.idList = [];
      switch (this.activeName) {
        case "first":
          this.$search(this.emergencyResponseTable);
          //删除参数
          this.deleteAPI_Id = [
            this.$api_hw.operationSafetyManagement_emergencyPlandDelete,
            "idList",
            "planId",
            "emergencyResponseTable"
          ];
          // 提交给城管局
          this.submitToData = [
            this.$api_hw.operationSafetyManagement_emergencyPlanCommit,
            "idList",
            "planId",
            "emergencyResponseTable"
          ];
          break;
        case "second":
          this.$search(this.emergencySuppliesTable);
          //删除参数
          this.deleteAPI_Id = [
            this.$api_hw.operationSafetyManagement_emergencySupplyDelete,
            "idList",
            "supplyId",
            "emergencySuppliesTable"
          ];
          // 提交给城管局
          this.submitToData = [
            this.$api_hw.operationSafetyManagement_emergencySupplyCommit,
            "idList",
            "supplyId",
            "emergencySuppliesTable"
          ];
          break;
        case "three":
          this.$search(this.emergencyDrillTable);
          //删除参数
          this.deleteAPI_Id = [
            this.$api_hw.operationSafetyManagement_emergencyDrillDelete,
            "idList",
            "drillId",
            "emergencyDrillTable"
          ];
          // 提交给城管局
          this.submitToData = [
            this.$api_hw.operationSafetyManagement_emergencyDrillCommit,
            "idList",
            "drillId",
            "emergencyDrillTable"
          ];
          break;
        case "four":
          this.$search(this.table);
          //删除参数
          this.deleteAPI_Id = [
            this.$api_hw
              .operationSafetyManagement_emergencyResponseRecordDelete,
            "idList",
            "responseId",
            "table"
          ];
          this.submitToData = [
            this.$api_hw
              .operationSafetyManagement_emergencyResponseRecordCommit,
            "idList",
            "responseId",
            "table"
          ];
          break;
      }
    },

    //新增
    onAdd(val) {
      this.tabsName = "val";
      //弹窗宽度
      this.widthForDialog = "50%";
      //显示弹窗
      this.isShowDialog = true;
      switch (val) {
        case "应急预案":
          //传views的url
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/add-emergency-response";
          break;
        case "应急物资管理":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/add-emergency-supplies";
          break;
        case "应急演练":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/add-emergency-drill";
          break;
        case "应急处置记录":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/add-emergency";
          break;
      }
    },
    //查看
    onInfo(val) {
      //传参给弹窗
      this.widthForDialog = "50%";
      this.isShowDialog = true; //显示弹窗
      switch (this.activeName) {
        case "first":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/info-emergency-response";
          this.dataForDialog = { id: val.planId };
          break;
        case "second":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/info-emergency-supplies";
          this.dataForDialog = { id: val.supplyId };
          break;
        case "three":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/info-emergency-drill";
          this.dataForDialog = { id: val.drillId };
          break;
        case "four":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/info-emergency";
          this.dataForDialog = { id: val.responseId };
          break;
      }
    },

    //编辑
    onEdit(val) {
      //传参给弹窗
      this.widthForDialog = "50%";
      this.isShowDialog = true; //显示弹窗
      switch (this.activeName) {
        case "first":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/edit-emergency-response";
          this.dataForDialog = { id: val.planId };
          break;
        case "second":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/edit-emergency-supplies";
          this.dataForDialog = { id: val.supplyId };
          break;
        case "three":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/edit-emergency-drill";
          this.dataForDialog = { id: val.drillId };
          break;
        case "four":
          this.viewUrlForDialog =
            "/operation-safety-management/emergency-management/edit-emergency";
          this.dataForDialog = { id: val.responseId };
          break;
      }
    },

    //删除
    deletes() {}
  },
  async mounted() {
    BUS.$on(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT1, () => {
      this.$search(this.emergencyResponseTable);
    });
    BUS.$on(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT2, () => {
      this.$search(this.emergencySuppliesTable);
    });
    BUS.$on(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT3, () => {
      this.$search(this.emergencyDrillTable);
    });
    BUS.$on(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT4, () => {
      this.$search(this.table);
    });
  }
};
</script>
<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid $-color-primary-3
  border-radius: 4px
  color: $-color-primary-3
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/.el-tabs__item.is-active
  color: $-color-primary-3
/deep/.el-tabs__active-bar
  background-color: $-color-primary-3
/deep/.el-tabs__item:hover
  color: $-color-primary-3
  cursor: pointer
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>